import i18n from '@/plugins/i18n'
import { isValidNumber, parsePhoneNumber } from 'libphonenumber-js'
import helpers from '@/helpers'

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const TIME_REGEX = /([0-1][0-9]|2[0-3]):([0-5][0-9])/

export default {
  isValidEmail: (value) => {
    return EMAIL_REGEX.test(value)
  },
  isValidTime: (value) => {
    return TIME_REGEX.test(value) || i18n.t('wrong_time_format')
  },
  required: (value) => !!value || i18n.t('error_required'),
  hasValue: (value) =>
    (value !== null && value !== undefined && value !== '') ||
    i18n.t('error_required'),
  legalAccepted: (value) => !!value || i18n.t('error_consent_missing'),
  // password policy: > 8 chars, +1 lowercase, +1 uppercase
  pwd: (value) => {
    if (!value) {
      return true
    }
    if (value.length < 8) {
      return i18n.t('pwd_rule_length')
    }
    const pwdPattern = /(?=.*[a-z])(?=.*[A-Z])/
    return pwdPattern.test(value) || i18n.t('pwd_rule_format')
  },
  email: (value) => {
    return !value || EMAIL_REGEX.test(value) || i18n.t('error_invalid_email')
  },
  phone: (value) => {
    if (!value) {
      return true
    }

    if (!isValidNumber(value)) {
      return i18n.t('signup_phone_error')
    }

    try {
      const phoneNumber = parsePhoneNumber(value)
      const country = phoneNumber.country.toLowerCase()

      return (
        helpers.isCountrySupported(country) || i18n.t('g_unsupported_country')
      )
    } catch (error) {
      return i18n.t('signup_phone_error')
    }
  },
  identifier: (value) => {
    // an identifier should be a valid email or phone number
    const condition = EMAIL_REGEX.test(value) || isValidNumber(value)
    return condition || i18n.t('invalid_identifier')
  },
  onlyPositive: (val) => val >= 0 || i18n.t('must_be_positive'),
  countryDefaultVAT: (val) => (val !== "" && val !== null) || i18n.t('country_default_vat')
}
